/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { Fragment } from "react"
import get from "lodash.get"
import { graphql } from "gatsby"
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"

import { headerThemeFromHeroColor } from "src/helpers"
import SEO from "src/components/seo"
import Header from "src/components/Header"
import SubPageNavigation, {
  getSubNavigationLinks,
} from "src/components/SubPageNavigation"
import HeroRenderer from "src/components/HeroRenderer"
import TableOfContents from "src/components/TableOfContents"
import FeatureModuleRenderer from "src/slices/FeatureModuleRenderer"
import BodyContentRenderer from "src/slices/BodyContentRenderer"
import RichContentRenderer from "src/slices/RichContentRenderer"
import Footer from "src/components/Footer"
import { EnquiryForm } from "src/components/Forms"

const Page = (props) => {
  const page = get(props, "data.wpgraphql.page", {})
  const layout = get(page, "acfPageHeader.layout", null)
  const currentTheme = get(
    page,
    "acfHeaderThemeColor.headerThemeColor",
    "default"
  )

  const headerTheme =
    layout === "image_background"
      ? "default"
      : headerThemeFromHeroColor(currentTheme)

  return (
    <Fragment>
      <SEO
        title={page.acfPageHeader.seoTitle || page.title}
        description={page.acfPageHeader.seoDescription}
        image={page.acfPageHeader.seoImage}
      />
      <SkipNavLink
        sx={{
          variant: "link.skipNav",
        }}
      />
      <Header
        defaultTheme={headerTheme}
        acfPageHeader={page.acfPageHeader}
        path={props.location.pathname}
        layout={page.acfPageHeader.layout}
        themeColor={page.acfHeaderThemeColor.headerThemeColor}
      />
      <main
        sx={{
          isolation: "isolate",
        }}
      >
        <SkipNavContent />
        <div
          sx={{
            ...(!!getSubNavigationLinks(page) && {
              ".hero-container": { mb: 0 },
            }),
          }}
        >
          <HeroRenderer
            title={page.title}
            acfPageHeader={page.acfPageHeader}
            breadcrumbs={page.appBreadcrumb}
            themeColor={page.acfHeaderThemeColor.headerThemeColor}
            acfLanguage={page.acfLanguage || {}}
          />
          <SubPageNavigation page={page} />
          <div
            {...(page.acfLanguage && page.acfLanguage.rtl && { dir: "rtl" })}
          >
            {page.acfTableOfContents.tableOfContents && (
              <TableOfContents
                acfFeatureModules={page.acfFeatureModules}
                acfBodyContent={page.acfBodyContent}
                acfRichContent={page.acfRichContent}
              />
            )}
            <FeatureModuleRenderer
              acfFeatureModules={page.acfFeatureModules}
              childPages={page.childPages}
            />
            <BodyContentRenderer acfBodyContent={page.acfBodyContent} />
            <RichContentRenderer acfRichContent={page.acfRichContent} />
          </div>
        </div>
        <Box mt={20}>
          <EnquiryForm location={props.location} />
        </Box>
      </main>
      <Footer />
    </Fragment>
  )
}

export default Page

export const pageQuery = graphql`
  query ($id: ID!) {
    wpgraphql {
      page: page(id: $id) {
        title
        uri
        pageTemplate
        databaseId
        parent {
          ... on WPGraphQL_Page {
            acfSubPageNavigation {
              subNavigation
              pageLinks {
                ... on WPGraphQL_Page {
                  uri
                  title
                }
              }
            }
          }
        }

        # -------------------------------
        # Child Pages
        # -------------------------------

        childPages(
          where: { orderby: { field: MENU_ORDER, order: ASC } }
          first: 12
        ) {
          nodes {
            title
            link
            acfPageHeader {
              introduction
            }
            date
          }
        }

        # RTL Updates

        # -------------------------------
        # Language RTL updates
        # -------------------------------

        acfLanguage {
          rtl
        }

        # MASTERQUERY START

        # -------------------------------
        # Header
        # -------------------------------

        acfHeaderThemeColor {
          headerThemeColor
        }

        acfPageHeader {
          layout
          title
          introduction
          seoTitle
          seoDescription
          seoImage {
            sourceRemote
            sourceLocal {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200, height: 630)
              }
            }
          }
          image {
            altText
            caption
            sourceRemote
            sourceLocal {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          buttonLink {
            title
            url
          }
          buttonColour
        }

        # -------------------------------
        # Breadcrumbs
        # -------------------------------

        appBreadcrumb {
          title
          link
        }

        # -------------------------------
        # Sub Heading
        # -------------------------------

        acfSubPageNavigation {
          subNavigation
          pageLinks {
            ... on WPGraphQL_Page {
              uri
              title
            }
          }
        }

        # -------------------------------
        # Table of contents
        # -------------------------------

        acfTableOfContents {
          tableOfContents
        }

        # -------------------------------
        # Feature Modules
        # -------------------------------

        acfFeatureModules {
          featureModulesContent {
            __typename
            ... on WPGraphQL_Page_Acffeaturemodules_FeatureModulesContent_CardsBlockAllHubs {
              sectionHeading
              active
            }
            ... on WPGraphQL_Page_Acffeaturemodules_FeatureModulesContent_FeatureCardsLinks {
              sectionHeading
              themeColor
              cards {
                ... on WPGraphQL_Page {
                  id
                  title
                  uri: link
                }
                ... on WPGraphQL_Article {
                  id
                  title
                  uri: link
                }
              }
            }
            ... on WPGraphQL_Page_Acffeaturemodules_FeatureModulesContent_DetailCardsChildPages {
              sectionHeading
              active
            }

            ... on WPGraphQL_Page_Acffeaturemodules_FeatureModulesContent_DetailCardsFilteredCollection {
              collectionType
              themeColor
            }

            ... on WPGraphQL_Page_Acffeaturemodules_FeatureModulesContent_DetailCardsEditableTiles {
              sectionHeading
              cards {
                cardTitle
                cardContent
                cardFooter
                link {
                  title
                  url
                }
              }
            }
          }
        }

        # -------------------------------
        # Body Content
        # -------------------------------

        acfBodyContent {
          bodyContent {
            __typename
            ... on WPGraphQL_Page_Acfbodycontent_BodyContent_Wysiwyg {
              contentHeading
              content
            }
            ... on WPGraphQL_Page_Acfbodycontent_BodyContent_Accordion {
              display
              contentHeading
              accordion {
                heading
                content: contentWysiwyg
                status
              }
            }
            ... on WPGraphQL_Page_Acfbodycontent_BodyContent_LargeImageBlock {
              image {
                altText
                sourceRemote
                sourceLocal {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 1200
                      height: 680
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
            ... on WPGraphQL_Page_Acfbodycontent_BodyContent_EditableTiles {
              sectionHeading
              cards {
                cardTitle
                cardContent
                cardFooter
                link {
                  title
                  url
                }
              }
            }
            ... on WPGraphQL_Page_Acfbodycontent_BodyContent_SmallImageBlock {
              contentHeading
              image {
                altText
                sourceRemote
                sourceLocal {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 1200
                      height: 680
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
            ... on WPGraphQL_Page_Acfbodycontent_BodyContent_VideoBlock {
              contentHeading
              videoUrl
            }
          }
        }

        # -------------------------------
        # Rich Content / Section Modules
        # -------------------------------

        acfRichContent {
          richContent {
            __typename
            ... on WPGraphQL_Page_Acfrichcontent_RichContent_BoldStatement {
              sectionHeading
              statement
              link: pageLink {
                title
                url
              }
            }
            ... on WPGraphQL_Page_Acfrichcontent_RichContent_KeyStats {
              sectionHeading
              themeColor
              stats {
                title
                body
                sourceUrl
                sourceText
              }
            }
            ... on WPGraphQL_Page_Acfrichcontent_RichContent_KeyStatsFeature {
              sectionHeading
              themeColor
              stats {
                title
                type
                size
                body
                sourceUrl
                sourceText
              }
            }
            ... on WPGraphQL_Page_Acfrichcontent_RichContent_KeyStatsList {
              sectionHeading
              themeColor
              list {
                heading
                stats {
                  title
                  body
                }
              }
              sourceText
              sourceUrl
            }
            ... on WPGraphQL_Page_Acfrichcontent_RichContent_BannerFullWidth {
              sectionHeading
              title
              introduction
              link: pageLink {
                title
                url
              }
              themeColor
              image {
                altText
                sourceRemote
                sourceLocal {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 900
                      height: 600
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
            ... on WPGraphQL_Page_Acfrichcontent_RichContent_BannerFullWidthGroup {
              image {
                altText
                sourceRemote
                sourceLocal {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 900
                      height: 600
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
              banners {
                themeColor
                title
                introduction
                link: pageLink {
                  title
                  url
                }
              }
            }
            ... on WPGraphQL_Page_Acfrichcontent_RichContent_RelatedResources {
              sectionHeading
              resources {
                ... on WPGraphQL_Resource {
                  uri
                  title
                  link
                  acfPageHeader {
                    title
                    introduction
                  }
                }
              }
            }

            ... on WPGraphQL_Page_Acfrichcontent_RichContent_RelatedCaseStudies {
              sectionHeading
              caseStudies {
                image {
                  sourceRemote
                  sourceLocal {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                        width: 790
                        height: 448
                        transformOptions: { cropFocus: CENTER }
                      )
                    }
                  }
                }
                title
                introduction
                description
                link {
                  title
                  url
                }
              }
            }

            ... on WPGraphQL_Page_Acfrichcontent_RichContent_Downloads {
              title
              introduction
              downloads {
                download {
                  title
                  fileSizeFormatted
                  fileTypeFormatted
                  sourceRemote
                  sourceLocal {
                    publicURL
                  }
                }
              }
            }

            ... on WPGraphQL_Page_Acfrichcontent_RichContent_GlobalModule {
              globalModule
            }
          }
        }

        # MASTERQUERY FINISH
      }
    }
  }
`
