/** @jsx jsx */
import React from "react"
import PropTypes from "prop-types"
import { jsx, Flex } from "theme-ui"
import { subHearderHeight } from "src/theme"
import ContainerWide from "src/components/ContainerWide"
import Link from "src/components/Link"
import formatURL from "src/helpers/formatURL"

export const getSubNavigationLinks = (page) => {
  // Check if sub nav on child page if not check if on parent page
  const pageSubNavigation =
    page.acfSubPageNavigation &&
    page.acfSubPageNavigation.subNavigation &&
    page.acfSubPageNavigation.pageLinks

  const parentSubNavigation =
    page.parent &&
    page.parent.acfSubPageNavigation &&
    page.parent.acfSubPageNavigation.subNavigation &&
    page.parent.acfSubPageNavigation.pageLinks

  return pageSubNavigation || parentSubNavigation
}

const SubPageNavigation = ({ page }) => {
  const pageLinks = getSubNavigationLinks(page)
  return pageLinks ? (
    <div
      sx={{
        backgroundColor: "#C4C4C4",
      }}
    >
      <ContainerWide
        sx={{
          overflow: "scroll",
          variant: "layout.containerWide",
          "@media (min-width:1000px)": {
            overflow: "auto",
            variant: "layout.container",
          },
        }}
      >
        <Flex
          sx={{
            whiteSpace: 'nowrap',
            "@media (min-width:1000px)": {
              whiteSpace: "normal",
            },
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: [...subHearderHeight],
            flexWrap: ["no-wrap", null, null, null, "wrap", "wrap"],
          }}
        >
          {pageLinks.map(({ title, uri }) => {
            return (
              <Link
                sx={{
                  fontSize: ["16px", null, null, null, null, "24px"],
                  color: "black",
                  textDecoration: "none",
                  padding: '10px 15px',
                }}
                activeStyle={{ textDecoration: "underline" }}
                key={title}
                //Not sure why I have to add the /. cannot change in wp end
                to={`${formatURL(uri)}`}
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            )
          })}
        </Flex>
      </ContainerWide>
    </div>
  ) : null
}

SubPageNavigation.propTypes = {
  page: PropTypes.shape({}).isRequired,
}

export default SubPageNavigation
