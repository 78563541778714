/** @jsx jsx */
import { jsx, Themed } from "theme-ui"

import PropTypes from "prop-types"
import Link from "src/components/Link"

const DetailCard = (props) => {
  return (
    <article
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        textDecoration: "none",
      }}
    >
      <Link
        to={props.link}
        aria-label={props.title}
        ref={props.firstNewResultRef}
        sx={{
          variant: `cards.whiteto.${
            props.themeColor ? props.themeColor.toLowerCase() : "white"
          }`,
          borderRadius: 8,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          textDecoration: "none",
          height: "100%",
          minHeight: [175, 175, 190],
          p: [3, null, 5, null, null, 6],
        }}
      >
        <div>
          {props.title && (
            <h3
              sx={{
                variant: "links.h4",
              }}
              dangerouslySetInnerHTML={{
                __html: props.title,
              }}
            ></h3>
          )}
          {props.introduction && (
            <Themed.p
              sx={{
                display: ["none", null, null, "block"],
              }}
            >
              {props.introduction.substring(0, 100)}
            </Themed.p>
          )}
        </div>

        {(props.footer || props.taxonomy) && (
          <footer>
            {props.footer && <p>{props.footer}</p>}

            {props.taxonomy && (
              <p
                sx={{
                  variant: "text.smallText",
                  mb: "0.25em",
                }}
              >
                {props.taxonomy}
              </p>
            )}
          </footer>
        )}
      </Link>
    </article>
  )
}

DetailCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  date: PropTypes.string,
  postDateFormatted: PropTypes.string,
  introduction: PropTypes.string,
  footer: PropTypes.string,
  themeColor: PropTypes.string,
}

DetailCard.defaultProps = {
  title: "Title",
  link: "/",
  date: null,
  postDateFormatted: null,
  introduction: null,
  footer: "",
  themeColor: "white",
}

export default DetailCard
