/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment, useRef, useEffect, useState } from "react"
import { connectInfiniteHits } from "react-instantsearch-dom"

import Container from "src/components/Container"
import ColumnsNarrow from "src/components/ColumnsNarrow"
import ColumnCardsNarrowQuarter from "src/components/ColumnCardsNarrowQuarter"
import DetailCard from "src/components/DetailCard"

const SearchHitsList = connectInfiniteHits(
  ({ hits, hasMore, refineNext, themeColor }) => {
    const firstNewResultRef = useRef(null)
    const [firstNewResultIndex, setFirstNewResultIndex] = useState(-1)

    // Whenever the index changes, focus the corresponding ref
    useEffect(() => {
      firstNewResultRef.current?.focus()
    }, [firstNewResultIndex])

    useEffect(() => {
      setFirstNewResultIndex(hits.length)
    }, [hits.length])

    return (
      <Container>
        {hits && hits.length > 0 ? (
          <Fragment>
            <ColumnsNarrow
              sx={{
                mb: 3,
              }}
            >
              {hits.map((hit, index) => {
                const isFirstNewResult = index === firstNewResultIndex
                return (
                  <ColumnCardsNarrowQuarter key={index}>
                    <DetailCard
                      themeColor={themeColor}
                      postType={hit.post_type}
                      title={hit.post_title}
                      link={hit.permalink}
                      taxonomies={hit.taxonomies}
                      postDateFormatted={hit.post_date_formatted}
                      firstNewResultRef={
                        isFirstNewResult ? firstNewResultRef : undefined
                      }
                    />
                  </ColumnCardsNarrowQuarter>
                )
              })}
            </ColumnsNarrow>
            {hasMore && (
              <button
                sx={{
                  variant: "button.whiteShadowed",
                  display: "block",
                  width: "100%",
                  textAlign: "center",
                }}
                disabled={!hasMore}
                onClick={refineNext}
              >
                See more
              </button>
            )}
          </Fragment>
        ) : (
          <div>Loading...</div>
        )}
      </Container>
    )
  }
)

export default SearchHitsList
