import React from "react"

// import LinkListArticles from "src/slices/LinkListArticles"

import LinkListHubs from "src/slices/LinkListHubs"
import FeatureCardsLinks from "src/slices/FeatureCardsLinks"

import DetailCardsChildPages from "src/components/DetailCardsChildPages"
import DetailCardsFilteredCollection from "src/components/DetailCardsFilteredCollection"
import DetailCardsEditableTiles from "src/components/DetailCardsEditableTiles"
import slugify from "slugify"

const matchingSlice = (matcher, typeName) => {
  const prefixes = [
    "WPGraphQL_Page_Acffeaturemodules_FeatureModulesContent",
    "WPGraphQL_Article_Acffeaturemodules_FeatureModulesContent",
  ]
  const matches = prefixes.map((prefix) => `${prefix}_${typeName}`)
  return matches.includes(matcher)
}

function FeatureModuleRenderer({ acfFeatureModules, childPages }) {
  return (
    acfFeatureModules.featureModulesContent.length > 0 &&
    acfFeatureModules.featureModulesContent.map((slice, index) => {
      return matchingSlice(slice.__typename, "CardsBlockAllHubs") ? (
        <LinkListHubs
          key={index}
          sectionHeading={slice.sectionHeading}
          sectionHeadingId={slice.sectionHeading ? slugify(slice.sectionHeading) : undefined}
          active={slice.active}
        />
      ) : matchingSlice(slice.__typename, "FeatureCardsLinks") ? (
        <FeatureCardsLinks
          key={index}
          sectionHeading={slice.sectionHeading}
          sectionHeadingId={slice.sectionHeading ? slugify(slice.sectionHeading) : undefined}
          links={slice.cards}
          themeColor={slice.themeColor}
        />
      ) : matchingSlice(slice.__typename, "DetailCardsChildPages") ? (
        childPages && (
          <DetailCardsChildPages key={index} childPages={childPages} />
        )
      ) : matchingSlice(slice.__typename, "DetailCardsFilteredCollection") ? (
        <DetailCardsFilteredCollection
          key={index}
          collectionType={slice.collectionType}
          themeColor={slice.themeColor}
        />
      ) : matchingSlice(slice.__typename, "DetailCardsEditableTiles") ? (
        <DetailCardsEditableTiles
          key={index}
          sectionHeading={slice.sectionHeading}
          sectionHeadingId={slice.sectionHeading ? slugify(slice.sectionHeading) : undefined}
          cards={slice.cards}
        />
      ) : null
    })
  )
}

FeatureModuleRenderer.propTypes = {}

export default FeatureModuleRenderer
