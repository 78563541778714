/** @jsx jsx */
import { jsx } from "theme-ui"

import SectionNarrow from "src/components/SectionNarrow"
import Container from "src/components/Container"
import ColumnsNarrow from "src/components/ColumnsNarrow"
import ColumnCardsNarrowQuarter from "src/components/ColumnCardsNarrowQuarter"
import DetailCard from "src/components/DetailCard"

const DetailCardsChildPages = ({ childPages }) => {
  const { nodes } = childPages
  return (
    nodes &&
    nodes.length > 0 && (
      <SectionNarrow
        sx={{
          variant: "section.large",
        }}
      >
        <Container>
          <ColumnsNarrow>
            {childPages.nodes.map((page, index) => {
              const {
                title,
                link,
                date,
                acfPageHeader: { introduction },
              } = page
              return (
                <ColumnCardsNarrowQuarter key={index}>
                  <DetailCard {...{ title, link, date, introduction }} />
                </ColumnCardsNarrowQuarter>
              )
            })}
          </ColumnsNarrow>
        </Container>
      </SectionNarrow>
    )
  )
}

export default DetailCardsChildPages
