/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { Fragment } from "react"
import PropTypes from "prop-types"
import algoliasearch from "algoliasearch"

import { useState, useEffect, useRef } from "react"
import orderBy from "lodash.orderby"
import ChevronDown from "src/assets/svg/chevron-down.inline.svg"
import ChevronUp from "src/assets/svg/chevron-up.inline.svg"
import {
  InstantSearch,
  Configure,
  RefinementList,
} from "react-instantsearch-dom"

import SectionVertical from "src/components/SectionVertical"
import Section from "src/components/Section"
import SectionNarrow from "src/components/SectionNarrow"
import Container from "src/components/Container"
import SearchHitsList from "src/components/SearchHitsList"

const Filter = ({ taxonomy, title, titleUnselected, refinements }) => {
  const node = useRef()
  const [active, setActive] = useState(false)
  const filterRefinements = refinements && refinements[`taxonomies.${taxonomy}`]

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return
    }
    setActive(false)
  }

  useEffect(() => {
    if (active) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [active])

  return (
    <div
      ref={node}
      sx={{
        width: "100%",
        // maxWidth: [null, null, null, "250px"],
        maxWidth: [null, null, null, "25%"],
      }}
    >
      <div
        sx={{
          position: "relative",
          mr: [null, null, null, "8px"],
          mb: "8px",
        }}
      >
        <button
          sx={{
            variant: "button.void",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: "1rem 1.15rem",
            fontSize: 2,
            width: "100%",
            boxShadow: "0px 4px 16px rgba(76, 48, 16, 0.16)",
            borderRadius: "4px",
            cursor: "pointer",
            bg: "white",
          }}
          onClick={() => setActive(!active)}
        >
          <div
            sx={{
              flex: 1,
              minWidth: 0,
            }}
          >
            {filterRefinements ? (
              <div
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "left",
                }}
              >
                {title} ({filterRefinements.length})
              </div>
            ) : (
              <div
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "left",
                }}
              >
                {titleUnselected}
              </div>
            )}
          </div>
          <span
            aria-hidden="true"
            sx={{
              display: "block",
              width: "13px",
              svg: { display: "block", width: "100%", height: "auto" },
            }}
          >
            {active ? <ChevronUp /> : <ChevronDown />}
          </span>
        </button>
        {active && (
          <span
            sx={{
              display: "block",
              position: "absolute",
              width: [20, null, null, 30],
              height: [20, null, null, 30],
              bg: "transparent",
              left: "50%",
              bottom: ["-8px", null, null, "-16px"],
              overflow: "hidden",
              transform: "translateX(-50%)",
              zIndex: 2,
              "&:after": {
                display: "block",
                content: `""`,
                position: "absolute",
                bottom: 0,
                left: "50%",
                width: "50%",
                height: "50%",
                bg: "white",
                transform: "translate(-50%, 50%) rotate(45deg) ",
              },
            }}
          ></span>
        )}
      </div>
      <div
        sx={{
          display: active ? "block" : "none",
          position: "absolute",
          maxWidth: "1100px",
          width: "100%",
          left: 0,
          marginTop: [null, null, null, "8px"],
          zIndex: 1,
        }}
      >
        <RefinementList
          sx={{
            variant: "searchFilter",
            overflow: "auto",
          }}
          limit={20}
          attribute={`taxonomies.${taxonomy}`}
          transformItems={(items) =>
            orderBy(items, ["label", "count"], ["asc", "desc"])
          }
        />
      </div>
    </div>
  )
}

const Filters = ({ indexName, refinements }) => {
  return (
    <div
      sx={{
        position: "relative",
        display: [null, null, null, "flex"],
      }}
    >
      {/* Articles */}
      {indexName.includes(
        process.env.GATSBY_ALGOLIA_PREFIX + "posts_article"
      ) && (
        <Filter
          taxonomy="hub"
          title="Hubs"
          titleUnselected="Select Hub/s"
          refinements={refinements}
        />
      )}

      {/* Post Type - Resources */}
      {indexName.includes(
        process.env.GATSBY_ALGOLIA_PREFIX + "posts_resource"
      ) && (
        <Fragment>
          <Filter
            taxonomy="topic"
            title="Topic"
            titleUnselected="Select Topic/s"
            refinements={refinements}
          />
          <Filter
            taxonomy="type"
            title="Type"
            titleUnselected="Select Type/s"
            refinements={refinements}
          />
          <Filter
            taxonomy="category-year"
            title="Year"
            titleUnselected="Select Year/s"
            refinements={refinements}
          />
        </Fragment>
      )}

      {/* Post Type - News */}
      {indexName.includes(process.env.GATSBY_ALGOLIA_PREFIX + "posts_news") && (
        <Fragment>
          <Filter
            taxonomy="topic-news"
            title="Topic"
            titleUnselected="Select Topic/s"
            refinements={refinements}
          />
          <Filter
            taxonomy="type-news"
            title="Type"
            titleUnselected="Select Type/s"
            refinements={refinements}
          />
          <Filter
            taxonomy="category-year"
            title="Year"
            titleUnselected="Select Year/s"
            refinements={refinements}
          />
        </Fragment>
      )}

      {/* Post Type - Policy Submission */}
      {indexName.includes(
        process.env.GATSBY_ALGOLIA_PREFIX + "posts_policy-submission"
      ) && (
        <Fragment>
          <Filter
            taxonomy="topic-policy-submission"
            title="Topic"
            titleUnselected="Select Topic/s"
            refinements={refinements}
          />
          <Filter
            taxonomy="type-policy-submission"
            title="Type"
            titleUnselected="Select Type/s"
            refinements={refinements}
          />
          <Filter
            taxonomy="category-year"
            title="Year"
            titleUnselected="Select Year/s"
            refinements={refinements}
          />
        </Fragment>
      )}

      {/* Post Type - Legal Intervention */}
      {indexName.includes(
        process.env.GATSBY_ALGOLIA_PREFIX + "posts_legal-intervention"
      ) && (
        <Fragment>
          <Filter
            taxonomy="act-li"
            title="Act"
            titleUnselected="Select Act/s"
            refinements={refinements}
          />
          <Filter
            taxonomy="attribute-li"
            title="Attribute"
            titleUnselected="Select Attribute/s"
            refinements={refinements}
          />
          <Filter
            taxonomy="operational-provision-li"
            title="Operational Provision"
            titleUnselected="Select Operational Provision/s"
            refinements={refinements}
          />
          <Filter
            taxonomy="human-right-li"
            title="Human Right"
            titleUnselected="Select Human Right/s"
            refinements={refinements}
          />
        </Fragment>
      )}
    </div>
  )
}

const DetailCardsFilteredCollection = ({ collectionType, themeColor }) => {
  const [refinements, setRefinements] = useState({})
  const indexName = collectionType.replace(
    /^wp_/,
    process.env.GATSBY_ALGOLIA_PREFIX
  )

  return (
    <InstantSearch
      searchClient={algoliasearch(
        process.env.GATSBY_ALGOLIA_APPID,
        process.env.GATSBY_ALGOLIA_APIKEY
      )}
      indexName={indexName}
      onSearchStateChange={({ refinementList }) => {
        setRefinements(refinementList)
      }}
    >
      <Configure hitsPerPage={12} />
      <SectionVertical as="section" sx={{ variant: "section.large" }}>
        <Section>
          <Container>
            <h2 sx={{ variant: "styles.h4" }}>Filter by</h2>
          </Container>
        </Section>
        <SectionNarrow>
          <Container sx={{ mb: 7 }}>
            <Filters indexName={indexName} refinements={refinements} />
            {/* <CurrentRefinements /> */}
          </Container>
          <SearchHitsList themeColor={themeColor} />
        </SectionNarrow>
      </SectionVertical>
    </InstantSearch>
  )
}

DetailCardsFilteredCollection.propTypes = {
  collectionType: PropTypes.string.isRequired,
  themeColor: PropTypes.string,
}

DetailCardsFilteredCollection.defaultProps = {
  themeColor: "White",
}

export default DetailCardsFilteredCollection
